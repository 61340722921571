import React from 'react';
import { graphql } from 'gatsby';
import SubmitAVideo from './SubmitAVideo';

const SubmitAVideoQuery = props => {
  return <SubmitAVideo {...props} />;
};

export default SubmitAVideoQuery;

export const query = graphql`
  fragment SubmitAVideo on ContentfulSubmitAVideo {
    eyebrow
    title
    body {
      childMarkdownRemark {
        html
      }
    }
    submissionEmail
    mobileImage {
      description
      fluid(maxWidth: 614, quality: 70) {
        ...GatsbyContentfulFluid_withWebp_noBase64
      }
    }
    desktopImage {
      description
      fluid(maxWidth: 1920, quality: 70) {
        ...GatsbyContentfulFluid_withWebp_noBase64
      }
    }
  }
`;

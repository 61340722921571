import React, { useState } from 'react';
import { InView } from 'react-intersection-observer';
import Img from 'gatsby-image/withIEPolyfill';
import { css } from '@utils';
import useWindowSize from '@hooks/useWindowSize';
import Styles from './SubmitAVideo.module.scss';

const SubmitAVideo = ({ eyebrow, title, body, mobileImage, desktopImage }) => {
  const { windowWidth } = useWindowSize();
  const [windowSize] = useState(windowWidth);

  return (
    <InView triggerOnce threshold={0.5}>
      {({ inView, ref }) => (
        <div
          className={css(Styles.contentContainer, inView && Styles.inView)}
          ref={ref}
        >
          <div className={Styles.bodyContainer}>
            <div className={Styles.innerBodyContainer}>
              <div className={Styles.eyebrow}>{eyebrow}</div>
              <h2 className={Styles.title}>{title}</h2>
              <p
                className={Styles.body}
                dangerouslySetInnerHTML={{
                  __html: body.childMarkdownRemark.html,
                }}
              />
            </div>
          </div>
          {windowSize <= 768 ? (
            <div className={Styles.mobileImage}>
              {mobileImage && mobileImage.fluid && (
                <Img
                  fluid={mobileImage.fluid}
                  objectFit="cover"
                  style={{ width: '100%', height: '100%' }}
                />
              )}
            </div>
          ) : (
            <div className={Styles.desktopImage}>
              {desktopImage && desktopImage.fluid && (
                <Img
                  fluid={desktopImage.fluid}
                  objectFit="cover"
                  style={{ width: '100%', height: '100%' }}
                />
              )}
            </div>
          )}
        </div>
      )}
    </InView>
  );
};

export default SubmitAVideo;

import React from 'react'
import { graphql } from 'gatsby'
import TrainingVideos from './TrainingVideos'

const TrainingVideosQuery = (props) => {
  return <TrainingVideos {...props} />;
};

export default TrainingVideosQuery;

export const query = graphql`
  fragment TrainingVideos on ContentfulTrainingVideo {
    title
    description
    videoLength
    youTubeId
    category
    mobilePreviewImage {
      description
      fluid(maxWidth: 614, quality: 70) {
        ...GatsbyContentfulFluid_withWebp_noBase64
      }
    }
    desktopPreviewImage {
      description
      fluid(maxWidth: 614, quality: 70) {
        ...GatsbyContentfulFluid_withWebp_noBase64
      }
    }
  }
`;

import React from 'react';
import { graphql } from 'gatsby';
import DefaultLayout from '@layouts';
import Meta from '@shared/Meta/Meta.query';
import Hero from '@components/Hero/Hero.query';
import DonationModule from '@shared/DonationModule/DonationModule.query';
import SocialModule from '@shared/SocialModule/SocialModule.query';
import SubmitAVideo from '../components/SubmitAVideo/SubmitAVideo.query';
import TrainingVideos from '../components/TrainingVideos/TrainingVideos.query';

const TrainingPage = ({ data }) => {
  const globalSiteData = data.allContentfulGlobalSiteData.edges.map(
    (edge) => edge.node
  )[0];
  const hero = data.allContentfulTrainingPage.edges.map((edge) => edge.node.hero)[0];
  const submitAVideo = data.allContentfulTrainingPage.edges.map((edge) => edge.node.submitAVideo)[0];
  const trainingVideos = data.allContentfulTrainingPage.edges.map((edge) => edge.node.trainingVideos)[0];

  const {
    facebookLink,
    twitterLink,
    instagramLink,
    socialModuleEyebrow,
    socialModuleTitle,
    socialModuleSubtitle,
    donationModuleEyebrow,
    donationModuleTitle,
    donationModuleSubtitle,
    mailingAddressLine1,
    mailingAddressLine2,
    mailingAddressCity,
    mailingAddressState,
    mailingAddressZipCode,
    cookieBannerCopy,
    cookieBannerCtaCopy,
    phoneNumber,
    contactEmails,
    youTubeLink,
  } = globalSiteData;

  const footerData = {
    mailingAddressLine1,
    mailingAddressLine2,
    mailingAddressCity,
    mailingAddressState,
    mailingAddressZipCode,
    phoneNumber,
    facebookLink,
    twitterLink,
    instagramLink,
    contactEmails,
    youTubeLink,
  };

  const cookieBanner = {
    cookieBannerCopy,
    cookieBannerCtaCopy,
  };

  return (
    <DefaultLayout
      footerData={footerData}
      cookieBanner={cookieBanner}
      pageName="Home"
    >
      <Meta {...globalSiteData} />

      <Hero {...hero} />
      <TrainingVideos trainingVideos={trainingVideos} />
      <SubmitAVideo {...submitAVideo} />

      <DonationModule
        donationModuleEyebrow={donationModuleEyebrow}
        donationModuleTitle={donationModuleTitle}
        donationModuleSubtitle={donationModuleSubtitle}
      />
      <SocialModule
        eyebrow={socialModuleEyebrow}
        title={socialModuleTitle}
        subtitle={socialModuleSubtitle}
        links={[
          {
            label: 'Instagram',
            url: instagramLink,
          },
          {
            label: 'Facebook',
            url: facebookLink,
          },
          {
            label: 'Twitter',
            url: twitterLink,
          },
          {
            label: 'YouTube',
            url: youTubeLink,
          },
        ]}
      />
    </DefaultLayout>
  );
};

export default TrainingPage;

export const pageQuery = graphql`
  {
    allContentfulGlobalSiteData {
      edges {
        node {
          ...Meta
          ...SocialModule
          ...DonationModule
        }
      }
    }

    allContentfulTrainingPage {
      edges {
        node {
          hero {
            ...Hero
          }
          submitAVideo {
            ...SubmitAVideo
          }
          trainingVideos {
            ...TrainingVideos
          }
        }
      }
    }
  }
`;

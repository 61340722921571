import React, { Fragment, useState } from 'react';
import { InView } from 'react-intersection-observer';
import Img from 'gatsby-image/withIEPolyfill';
import useWindowSize from '@hooks/useWindowSize';
import { css } from '@utils';
import { PlayIcon } from '@shared/Icons/Icons';
import YoutubeIframe from '@shared/YoutubeIframe/YoutubeIframe';
import Styles from './TrainingVideos.module.scss';

const TrainingVideos = ({ trainingVideos }) => {
  const { windowWidth } = useWindowSize();
  const [activeYoutubeIndex, setActiveYoutubeIndex] = useState(-1);
  const handleClick = index => {
    setActiveYoutubeIndex(index);
  };
  return (
    <div className={Styles.trainingVideosContainer}>
      {trainingVideos.map(
        (
          {
            title,
            mobilePreviewImage,
            desktopPreviewImage,
            videoLength,
            description,
            youTubeId,
          },
          index
        ) => (
          <Fragment key={title}>
            <InView triggerOnce threshold={0.5}>
              {({ inView, ref }) => (
                <div
                  className={css(
                    Styles.videoContainer,
                    index === 0 && Styles.featured,
                    index > 0 && index % 2 === 0 && Styles.leftImage,
                    inView && Styles.inView
                  )}
                  key={title}
                  ref={ref}
                >
                  {windowWidth <= 768 ? (
                    <div className={Styles.mobileImage}>
                      <div className={Styles.newVideoBanner}>New Video</div>
                      <div className={Styles.videoOverlay}>
                        <button
                          className={Styles.playIconContainer}
                          type="button"
                          onClick={() => handleClick(index)}
                        >
                          <PlayIcon iconStyles={Styles.playIcon} />
                        </button>
                      </div>
                      {mobilePreviewImage && mobilePreviewImage.fluid && (
                        <Img
                          fluid={mobilePreviewImage.fluid}
                          objectFit="cover"
                          style={{ width: '100%', height: '100%' }}
                        />
                      )}
                    </div>
                  ) : (
                    <div className={Styles.desktopImage}>
                      <div className={Styles.newVideoBanner}>New Video</div>
                      <div className={Styles.videoOverlay}>
                        <button
                          type="button"
                          onClick={() => handleClick(index)}
                          className={Styles.videoActionCursor}
                        >
                          Play
                        </button>
                      </div>
                      {desktopPreviewImage && desktopPreviewImage.fluid && (
                        <Img
                          fluid={desktopPreviewImage.fluid}
                          objectFit="cover"
                          style={{ width: '100%', height: '100%' }}
                        />
                      )}
                    </div>
                  )}
                  <div className={Styles.contentContainer}>
                    <div>
                      <span className={Styles.eyebrow}>
                        <span className={Styles.length}>{videoLength}</span>
                        <span>
                          &nbsp;|
                          {index === 0 ? 'Featured Video' : 'Training Video'}
                        </span>
                      </span>
                    </div>
                    <h3 className={Styles.title}>{title}</h3>
                    <p className={Styles.description}>{description}</p>
                  </div>
                </div>
              )}
            </InView>
            <YoutubeIframe
              key={index}
              youtubeVideoId={youTubeId}
              active={index === activeYoutubeIndex}
              onClose={() => {
                setActiveYoutubeIndex(-1);
              }}
            />
          </Fragment>
        )
      )}
    </div>
  );
};

export default TrainingVideos;
